export default defineNuxtRouteMiddleware(async to => {
  const { user } = useAuthStore()

  if (user.group === 'Partner') {
    if (to.fullPath.includes('/admin/mandanten')) {
      return
    }
    return navigateTo('/admin/mandanten')
  }
  return
})
